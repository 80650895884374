// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";
//@import "~ng-zorro-antd/ng-zorro-antd.compact.less";
// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// @primary-color: #1890ff;
/* You can add global styles to this file, and also import other style files */

body {
    font-family: Tahoma, Geneva, sans-serif
}

.search-bar {
    height: auto;
    min-height: 64px;
    width: 100%;
    background-color: #f0f2f5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    
}

.search-bar>input {
    width: 140px;
}

.search-bar>* {
    margin: 0px 4px;
}

.search-result {
    // height: calc(100vh - 176px);
    // width: calc(100vw - 248px);
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 8px;
}

.table-options {
    margin-bottom: 8px;
}

.ant-typography.ant-typography-secondary {
    color: #696969
}

.ant-table,
.ant-table.ant-table-middle,
.ant-table.ant-table-small {
    font-size: 13px;
}

.checkIcon {
    color: #1890ff;
    margin: 0px 8px;
    font-size: 18px;
}

.closeIcon {
    color: red;
    margin: 0px 8px;
    font-size: 18px;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
    padding: 8px;
}
.ant-layout-sider-children{
    overflow: auto;
}